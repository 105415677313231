// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-post-page-postpage-tsx": () => import("./../src/components/PostPage/postpage.tsx" /* webpackChunkName: "component---src-components-post-page-postpage-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-all-posts-tsx": () => import("./../src/pages/all-posts.tsx" /* webpackChunkName: "component---src-pages-all-posts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

